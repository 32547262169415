import "./Images.css";
import ImageComponent from "./ImageComponent";
import MultiView from "../../images/Multi.jpeg";
import NaturesBeauty from "../../images/Jungle.jpeg";
import Gnome from "../../images/Gnome.jpeg";
import MysticalEye from "../../images/Tiger.jpeg";
import MTV from "../../images/MTV.jpeg";
import Church from "../../images/Church.jpeg";
import DarkEye from "../../images/DarkEye.jpeg";
import Desert from "../../images/Desert.jpeg";
import FlowersExpanded from "../../images/FlowersExpanded.jpeg";
import MultiCards from "../../images/MultiCard.jpeg";
import HeartCard from "../../images/HeartCard.jpeg";
import Cac from "../../images/cac.jpeg"
import Colors from "../../images/colors.jpeg"
import Splash from "../../images/splash.jpeg"
import Eye from "../../images/eye.jpeg"
import Phoenix from "../../images/phoenix.jpeg"
const ImageGenerator = () => {
    return(
    <section id={"second-container"} className="d-flex flex-column flex-md-row">
        <div className="d-flex flex-column">
            {ImageComponent(MultiView, "An Eclectic View", "A partial collection of my art")}
            {ImageComponent(Eye, "Imaginary Friend (AVAILABLE)", "24\" x 36\" x 3/4\" acrylic on canvas in floating frame")}
            {ImageComponent(Phoenix, "Phoenix (SOLD)", "18\" x 24\" x 1.5\" gallery wrapped")}

            {ImageComponent(Cac, "Saguaro (AVAILABLE)","20\" x 20\" x 1.5\" mixed media on level 3 gallery wrapped canvas")}
            {ImageComponent(Splash, "An Angel Among Us (SOLD)","18\" x 24\" x 1.5\" acrylic on canvas in floating frame")}
            {ImageComponent(Colors, "Warehouse Art District (AVAILABLE)","18\" x 24\" x 1.5\" mixed media on canvas in floating frame")}
            {ImageComponent(NaturesBeauty, "Nature's Beauty (SOLD)", "3’6” x 4’8” acrylic multiple types on repurposed canvas")}
            {ImageComponent(Gnome, "Valentine's Gnome (SOLD)", "12” x 12” acrylic on canvas")}
            {ImageComponent(MysticalEye, "Mystical Eye (SOLD)", "12” x 12” acrylic on canvas")}
            {ImageComponent(MTV, "I Want My MTV (GIFTED)", "16” x 20” acrylic on canvas")}
            {ImageComponent(Church, "Calvary Episcopal Church Bastrop, TX (GIFTED)", "8” x 10” acrylic on canvas in frame")}
            {ImageComponent(DarkEye, "Mystical Eye 1 (GIFTED)", "16” x 20” acrylic on canvas in frame")}
            {ImageComponent(Desert, "Forever Sunrise (SOLD)", "16” x 20” acrylic on canvas in frame")}
            {ImageComponent(FlowersExpanded, "Holiday/Seasonal Cards", "5” x 7” watercolor/mixed media on cold press with envelopes ")}
            {ImageComponent(MultiCards, "Holiday/Seasonal Cards", "5” x 7” watercolor/mixed media on cold press with envelopes ")}
            {ImageComponent(HeartCard, "Holiday/Seasonal Cards", "5” x 7” watercolor/mixed media on cold press with envelopes")}
        </div>
        </section>
        )
}
export default ImageGenerator