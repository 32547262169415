import "./Header.css";
const Header = () => {
    return(
    <section id="main-container">
        <h1 className="title-text">Resinates</h1>
        <h2 className="subtext">By: Annie Gonzales</h2>
        <h2 className="subtext">Similar to life, this website is in a constant state of change.</h2>
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120"
             fill="white" className="bi bi-arrow-down arrow" id="arrow-animation" viewBox="0 0 16 16">
            <path fillRule="evenodd"
                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/>
        </svg>
    </section>
    )
}
export default Header;