import {Link} from "react-router-dom";

const Navbar = () => {
    return(
        <nav>
            <Link to={"/"}>Home</Link>
            <Link to={"/art"}>Art</Link>
            <Link to={"/about"}>About</Link>
            <Link to={"/contact"}>Contact Me</Link>
        </nav>
    )
}

export default Navbar;