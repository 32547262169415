import './App.css';
import Navbar from "./Components/Navbar/Navbar";
import Header from "./Components/Header/Header";
import ImageGenerator from "./Components/ArtImages/ImageGenerator"

import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Social from "./Components/Social/Social";
function App() {
  return (
      <div>

        {Header()}
        {ImageGenerator()}
        {About()}
        {Contact()}
        {Social()}
      </div>
  );
}

export default App;
