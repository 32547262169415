import { useForm, ValidationError } from '@formspree/react';
import "./Contact.css"

const Contact = () => {

        const [state, handleSubmit] = useForm("xbjnagjj");
        if (state.succeeded) {
            return <p>Thanks for joining!</p>;
        }
        return (
            <section id={"contact-section"}>
                    <h1 id={"contact-header"}>Contact Me</h1>
            <div id={"contact-container"}>

            <form id={"form-style"} onSubmit={handleSubmit}>
                    <label htmlFor="name">
                            Name
                    </label>
                    <input
                        id="name"
                        type="text"
                        name="name"
                    />
                    <ValidationError
                        prefix="Name"
                        field="name"
                        errors={state.errors}
                    />
                    <label htmlFor="email">
                            Email
                    </label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                    />
                    <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                    />
                    <label htmlFor="message">
                            Comment
                    </label>
                    <textarea
                        id="message"
                        name="message"
                    />
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                    />
                    <button id={"submit-button"} type="submit" disabled={state.submitting}>
                            Submit
                    </button>
            </form>
            </div>
            </section>
        );
}
export default Contact;