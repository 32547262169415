import  "../../App.css"
const ImageComponent = (imagePath, imageTitle, imageSubtitle) =>{
    return(
        <div>
        <img src={imagePath} alt="Painting" className={"images"}/>
        <h1 className={"image-header"}>{imageTitle}</h1>
        <p className={"image-subheader"}>{imageSubtitle}</p>
        </div>
    )
}
export default ImageComponent;